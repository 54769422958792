import React from "react";
import { Link } from "gatsby";
import Layout from "../components/layout-default";
import Hero from "../components/hero";
import Breadcrumbs from "../components/breadcrumbs";
import RfLatin from "../images/latin.jpg";
import RfReno from "../images/reno.jpg";
import BravoFest from "../images/LABravoFest.jpg";

import "../styles/mindbody.scss";
const crumbs = [
  {
    label: "Home",
    link: "",
  },
  {
    label: "Teams",
    link: "Teams",
  },
  {
    label: "Performance & Events",
    link: "performance-events",
  },
];

const Performance = () => {
  return (
    <>
      <Layout
        title="Best Studio Rentals in Orange County | RF Dance"
        description="Discover the best studio rentals in Orange County and unlock a world of possibilities for your next project. Book now and start turning your vision into reality."
        pathname="account"
        noHero="no-hero"
      >
        <Hero titleRed={`RF`} titleWhite={`Performance & Events`} />
        <div className="content-block all-performance">
          <div className="container">
            <Breadcrumbs crumbs={crumbs} />
            <div className="news_area">

            <div className="events_performance">
                <div className="news_item">
                  <div className="news_content">
                    <h1 className="news_heading">
                      RF Golden Rose Take Over Performer Sign Up
                    </h1>
                    <h3 className="news_heading">
                      RF Golden Rose Take Over Performer Sign Up 01/30/2025
                    </h3>
                    <h4 className="news_heading">Location:</h4>
                    <p>7115 Beach Blvd, Buena Park, CA 90620</p>
                    <p>
                      RF Takeovers are great to showcase our dedication and
                      talent. Let’s represent RF Dance with pride, celebrate our
                      hard work, and enjoy some fun time together. Looking
                      forward to a successful event. Let’s make it a great RF
                      Takeover! (Bring your ID’s and complete costume)
                    </p>
                    <h4 className="news_heading">Line Up:</h4>
                    <p>Call time | Showtime </p>
                    <u>
                      <b>
                        <p>Set 1</p> <p>8:00pm | 9:00pm</p>{" "}
                      </b>
                    </u>
                    <p>(1)Bachata 1 (2) Salsa 2 </p>
                    <u>
                      <b>
                        <p>Set 2 </p>
                        <p>8:30pm | 9:30pm</p>
                      </b>
                    </u>
                    <p>(3)Cumbia (4)Salsa 1 (5) Bachata 3</p>
                    <u>
                      <b>
                        <p>Set 3</p>
                        <p> 9:00pm | 10:00pm</p>
                      </b>
                    </u>
                    <p> (6)Bachata 2 (7)Salsa 3</p>
                    <p>
                      {" "}
                      <b>(Line Up is subject to changes)</b>
                    </p>
                    <div className="register_link">
                      <Link to="https://clients.mindbodyonline.com/classic/ws?studioid=222416&stype=-7&sTG=59&sVT=90&sLevel=8&sView=day&sLoc=0&sTrn=100000131&date=01/30/25">
                        Link
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

              <div className="events_performance">
                <div className="news_item">
                  <div className="news_content">
                    <h1 className="news_heading">
                      RF Masquerade Social Performer Sign Up
                    </h1>
                    <h3 className="news_heading">
                      RF Masquerade Social Performer Sign Up 01/31/2025
                    </h3>
                    <h4 className="news_heading">Location:</h4>
                    <p>RF Dance Center 1517 N. Main St, Santa Ana, CA 92701</p>
                    <p>
                      RF Takeovers are great to showcase our dedication and
                      talent. Let’s represent RF Dance with pride, celebrate our
                      hard work, and enjoy some fun time together. Looking
                      forward to a successful event. Let’s make it a great RF
                      Takeover! (Bring your ID’s and complete costume)
                    </p>
                    <h4 className="news_heading">Line Up:</h4>
                    <p>Call time | Showtime </p>
                    <u>
                      <b>
                        <p>Set 1</p> <p>8:00pm | 9:00pm</p>{" "}
                      </b>
                    </u>
                    <p>(1)Bachata 1 (2) Salsa 2</p>
                    <u>
                      <b>
                        <p>Set 2 </p>
                        <p>8:30pm | 9:30pm</p>
                      </b>
                    </u>
                    <p>(3)Cumbia (4)Salsa 1 (5) Bachata 3</p>
                    <u>
                      <b>
                        <p>Set 3</p>
                        <p>9:00pm | 10:00pm</p>
                      </b>
                    </u>
                    <p>(6)Bachata 2 (7)Salsa 3</p>
                    <p>
                      {" "}
                      <b>(Line Up is subject to changes)</b>
                    </p>
                    <div className="register_link">
                      <Link to="https://clients.mindbodyonline.com/classic/ws?studioid=222416&stype=-7&sTG=59&sVT=90&sLevel=4&sView=day&sLoc=1&sTrn=100000131&date=01/31/25">
                        Link
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

              <div className="events_performance" style={{width : "100%"}}>
                <div className="news_item">
                  <div className="news_content">
                    <h1 className="news_heading">
                      RF Team Season Finale Showcase Video & Photoshoot
                    </h1>
                    <h3>English:</h3>
                    <h3 className="news_heading">
                    
                    RF Team Season Showcase Video & Photoshoot 
                    </h3>
                    <h4 className="news_heading">Schedule:</h4>
                    <ul>
                      <li> <p>Monday, Feb 3rd: B1 Team</p></li>
                      <li><p>Tuesday, Feb 4th: S2 & B3 Teams</p></li>
                      <li><p>Monday, Feb 3rd: B1 Team</p></li>
                      <li><p>Monday, Feb 3rd: B1 Team</p></li>
                    </ul>
                    <p>Time: 9:00 PM – 10:30 PM</p>
                    <p>
                    Important: All team members must arrive on time and be fully ready with costumes, hair styled, and makeup done.
                    </p>
                    <br />

                    <h3>Espanol:</h3>
                    <h3 className="news_heading">
                    Video y Sesión de Fotos del Showcase de Temporada del Equipo RF 
                    </h3>
                    <h4 className="news_heading">Horario:</h4>
                    <ul>
                      <li><p>Lunes 3 de febrero: Equipo B1</p></li>
                      <li><p>Martes 4 de febrero: Equipos S2 y B3</p></li>
                      <li><p>Miércoles 5 de febrero: Equipos S3 y B2</p></li>
                      <li><p>Jueves 6 de febrero: Equipo Cumbia y S1</p></li>
                    </ul>
                    <p>Hora: 9:00 PM – 10:30 PM</p>
                    <p>
                    Importante: Todos los miembros del equipo deben llegar puntualmente y completamente listos con sus vestuarios, peinados, y maquillaje completos.
                    </p>
                    <div className="register_link">
                      <Link to="https://clients.mindbodyonline.com/classic/ws?studioid=222416&stype=-7&sTG=59&sVT=90&sView=day&sLoc=1&sTrn=100000131&date=02/03/25">
                        Link
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

              <div className="events_performance">
                <div className="news_item">
                  <div className="news_content">
                    <h1 className="news_heading">
                      RF Hideaway Take Over Performer Sign Up
                    </h1>
                    <h3 className="news_heading">
                      RF Hideaway Take Over Performer Sign Up 02/19/2025
                    </h3>
                    <h4 className="news_heading">Location:</h4>
                    <p>3660 Mission Inn Avenue, Riverside, CA 92501</p>
                    <p>
                      RF Takeovers are great to showcase our dedication and talent. Let’s represent RF Dance with pride, celebrate our hard work, and enjoy some fun time together. Looking forward to a successful event. Let’s make it a great RF Takeover!  (Bring your ID’s and complete costume)
                    </p>
                    <h4 className="news_heading">Line Up:</h4>
                    <p>Call time | Showtime </p>
                    <u>
                      <b>
                        <p>Set 1</p> <p>8:00pm | 9:00pm</p>{" "}
                      </b>
                    </u>
                    <p>(1)Bachata 1 (2) Salsa 2 </p>
                    <u>
                      <b>
                        <p>Set 2 </p>
                        <p>8:30pm | 9:30pm</p>
                      </b>
                    </u>
                    <p>(3)Cumbia (4)Salsa 1</p>
                    <u>
                      <b>
                        <p>Set 3</p>
                        <p> 9:00pm | 10:00pm</p>
                      </b>
                    </u>
                    <p> (5)Bachata 2 (6)Salsa 3</p>
                    <p>
                      {" "}
                      <b>(Line Up is subject to changes)</b>
                    </p>
                    <div className="register_link">
                      <Link to="https://clients.mindbodyonline.com/classic/ws?studioid=222416&stype=-7&sTG=59&sVT=90&sView=day&sLoc=0&sTrn=100000131&date=02/19/25">
                        Link
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
        
              


            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Performance;
